<template>
    <main>

        <!-- Modal -->
        <div class="modal fade" id="historyProccedure" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Histórico de procedimientos</h5>
                        <button class="btn btn-transparent text-bold" type="button" @click="closeModal()"
                            aria-label="Close"><i class="fa fa-times-circle fa-fw"></i></button>
                    </div>
                    <div class="modal-body">
                        <data-grid :data="pagination" @changePage="changePage($event)"
                            @perPageChange="perPageChange($event)" @search="search($event)" :searchBox="false">
                            <template #grid>
                                <div class="card mb-4 shadow-none" v-for="(pag, i) in pagination.data" :key="`odontograma_${i}`">
                                    <div class="card-body">
                                    <div class="card shadow-none">
                                        <div class="card-body">
                                            <h5>Historia No. {{ pag.id_historia }}  
                                                <button @click="imprimir(pag.id_historia);"
                                                        class="btn btn-primary  btn-xs"
                                                        title="Imprimir Historia">
                                                        <i class="fa fa-print fa-fw"></i>Ver H.C Completa
                                                    </button></h5>
                                            <h5>Fecha : {{ pag.historia.fecha | formatDate}}</h5>
                                            <h5>Profesional : {{ pag.historia.user.name }}</h5>
                                        </div>
                                    </div>
                                    <historyByHc :idHistoria="pag.id_historia" :procedimientos="pag.detalles"></historyByHc>
                                </div>
                                </div>
                            </template>
                        </data-grid>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" type="button" @click="closeModal()"><i
                                class="fa fa-times-circle fa-fw"></i>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historiaOdontogramaProcedimientoService from '../../../../services/historiaOdontogramaProcedimientoService';
import $ from 'jquery';
import Toast from '../../../../components/common/utilities/toast';
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import historyByHc from './baseHistory/historyByHc.vue';
import historiaClinicaService from '../../../../services/historiaClinicaService';

export default {
    props: ['idUsuario'],
    components: { DataGrid,historyByHc },
    data() {
        return {
            results: [],
            pagination: {
                data: [],
                current_page: 1,
                page: 1,
                per_page: 1,
                search: '',
                total: '',
                params: {
                    id_user: "",
                    fecha: "",
                },
            },
            
        }
    },
    methods: {
        async getResults() {

            try {
                this.LoaderSpinnerShow();

                const response = (await historiaOdontogramaProcedimientoService.showByIdUsuario(this.pagination.search, this.pagination, this.idUsuario))?.data;

                this.pagination.data = response.data.map(x => {
                    return {
                        ...x, 
                    }
                });
                this.pagination.current_page = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        openModal() {
            this.$nextTick(() => {
                $("#historyProccedure").modal("show");
                this.getResults();
            });
        },
        closeModal() {
            $("#historyProccedure").modal("hide");
        },
        changePage(page) {
            this.pagination.page = page;
            this.getResults();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getResults();
        },
        search(query) {
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getResults();
        },
        imprimir(id) {
            window.open(historiaClinicaService.print(id));
        },
    },
}
</script>