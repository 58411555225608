<template>
    <div class="">
      <div class="card shadow-none mt-2">
        <div class="card-header text-center bg-light">Dientes Permanentes</div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6" v-for="(item, index) in permanentTeethByQuadrant" :key="index">
                <div class="row p-0">
                  <v-stage :config="configKonva" :ref="'stagePermanent' + index">
                    <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                      <div class="justify-content-between p-0 ">
                        <Pieza :index="index" :teeth="teeth" :conventions="apliedConventions"
                          >
                        </Pieza>
                      </div>
                    </div>
                  </v-stage>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-none mt-2">
          <div class="card-header text-center bg-light">Dientes Temporales</div>
            <div class="card-body ">
              <div class="row">
                <div class="col-lg-6" v-for="(item, index) in temporaryTeethByQuadrant" :key="index">
                  <div class="row">
                    <v-stage :config="configKonva" :ref="'stageTemporary' + index">
                      <div v-for="(teeth, index) in item.teeths" :key="teeth.id">
                        <div class="justify-content-between">
                          <Pieza :index="index" :teeth="teeth" :conventions="apliedConventions"
                           >
                          </Pieza>
                        </div>
                      </div>
                    </v-stage>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive mt-2">
          <table class="table table-bordered mb-0 table-sm">
            <thead class="border-bottom">
              <tr class="small text-uppercase text-muted table-active">
                <th scope="col" colspan="2">Procedimientos Realizados</th>
              </tr>
            </thead>
            <thead class="border-bottom">
              <tr class="small text-uppercase text-muted table-active">
                <th scope="col">Diente</th>
                <th scope="col">Procedimiento</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in procedimientos" :key="index">
                    <td>{{ item.dentNumber }}</td>
                    <td>({{ item.procedimiento.servicio.codigo }}) - {{ item.procedimiento.servicio.descripcion }}</td>
                </tr>
          </tbody>
        </table>
    </div>
    
        <div class="table-responsive mt-2">
          <table class="table table-bordered mb-0 table-sm">
            <thead class="border-bottom">
              <tr class="small text-uppercase text-muted table-active">
                <th scope="col" colspan="2">Servicios Adicionales</th>
              </tr>
            </thead>
            <thead class="border-bottom">
              <tr class="small text-uppercase text-muted table-active">
                <th scope="col">Servicio</th>
                <th scope="col">Cantidad</th>
              </tr>
            </thead>
            <tbody v-if="!serviciosAdicionales.length">
                <tr>
                    <td colspan="2">Ninguno</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(item, index) in serviciosAdicionales" :key="index">
                    <td>({{ item.servicio.codigo }}) - {{ item.servicio.descripcion }}</td>
                    <td>{{ item.cantidad }}</td>
                </tr>
          </tbody>
        </table>
    </div>
    </div>
  
  </template>
  
  <script>
  import Pieza from "../pieza/pieza.vue"
  import odontogramaAdulto from "@/assets/odontograma/odontogramaAdulto.json";
  import historiaOdontogramaProcedimientoService from '@/services/historiaOdontogramaProcedimientoService';
  import { isEmpty } from 'lodash';
  export default {
    components: {
      Pieza
    },
    props: ['idHistoria','procedimientos'],
    data() {
      return {
        configKonva: {
          width: 400,
          height: 80,
        },
        permanentTeeth: odontogramaAdulto.permanent,
        temporaryTeeth: odontogramaAdulto.temp,
        conventions: [],
        apliedConventions: [],
        selectedConvention: null,
        permanentTeethByQuadrant: [],
        temporaryTeethByQuadrant: [],
        imgQuadrants: [],
        serviciosAdicionales:[]
      };
    },
    methods: {
      groupTeethsByQuadrant() {
  
        const groupPermanentTeeth = {};
  
        this.permanentTeeth.forEach((teeth) => {
  
          const quadrantKey = `cuadrante_${teeth.quadrant}`;
  
          if (!groupPermanentTeeth[quadrantKey]) {
            groupPermanentTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
          }
  
          groupPermanentTeeth[quadrantKey].teeths.push(teeth);
        });
  
        this.permanentTeethByQuadrant = { ...groupPermanentTeeth };
  
        const groupTemporaryTeeth = {};
  
        this.temporaryTeeth.forEach((teeth) => {
  
          const quadrantKey = `cuadrante_${teeth.quadrant}`;
  
          if (!groupTemporaryTeeth[quadrantKey]) {
            groupTemporaryTeeth[quadrantKey] = { quadrant: quadrantKey, teeths: [] };
          }
  
          groupTemporaryTeeth[quadrantKey].teeths.push(teeth);
        });
  
        this.temporaryTeethByQuadrant = { ...groupTemporaryTeeth };
  
      },
      async getServiciosAdicionales(id){
        this.serviciosAdicionales=[];
            const response=await historiaOdontogramaProcedimientoService.showProcedimientosRealizadosByHc(id);
                if(!isEmpty(response.data)){
                    this.serviciosAdicionales = response.data.items.map(x=>{
                        return {
                            servicio:x.servicio,
                            cantidad:x.cantidad
                        }
                    });
                
                }
        },
        
    },
    async mounted() {
      this.groupTeethsByQuadrant();
      
      if (this.idHistoria) { // Verificar que idHistoria no sea null o undefined
    const response = await historiaOdontogramaProcedimientoService.showByHc(this.idHistoria);
    if (!isEmpty(response.data)) {
      this.apliedConventions = response.data.detalles.map(x => ({
        conventionInfo: x.procedimiento,
        faceInfo: {
          dentNumber: x.dentNumber,
          faceCode: x.faceCode
        }
      }));
    }
    await this.getServiciosAdicionales(this.idHistoria);
  }
    },
    computed: {
      groupTheet() {
  
        const numerosDientesSet = new Set();
  
        this.apliedConventions.forEach(item => {
          numerosDientesSet.add(item.faceInfo.dentNumber);
        });
        // Convertir el Set de nuevo a un array para mantener la estructura de datos consistente
        const numerosDientes = Array.from(numerosDientesSet);
  
        const teeths = [];
  
        this.permanentTeeth.forEach(item => {
          if (numerosDientes.includes(item.number)) {
            teeths.push(item);
          }
        });
  
        this.temporaryTeeth.forEach(temporay => {
          if (numerosDientes.includes(temporay.number)) {
            teeths.push(temporay);
          }
        });
  
        return teeths;
      }
    },
    watch: {
      idHistoria: async function (newVal) {
        if (!newVal) return; 
          const response = await historiaOdontogramaProcedimientoService.showByHc(this.idHistoria);
          if (!isEmpty(response.data)) {
            this.apliedConventions = response.data.detalles.map(x => {
              return {
                conventionInfo: x.procedimiento,
                faceInfo: {
                  dentNumber: x.dentNumber,
                  faceCode: x.faceCode
                }
              }
            })
          }
          this.getServiciosAdicionales(this.idHistoria);
        
      }
    }
  };
  </script>
  
  <style scoped>
  .number-column {
    width: 100px;
  }
  
  .face-column {
    width: 150px;
  }
  
  .convention-column {
    width: 150px;
  }
  
  .pointer {
    cursor: pointer;
  }
  </style>