import Service from "./Service";
const baseurl = '/api/historia-odontograma-procedimiento';
export default {
    index() {
        return Service.get(`${baseurl}`);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    storeGraphics(obj) {
        return Service.post(`${baseurl}/store-graphics`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    showByHc(id) {
        return Service.get(`${baseurl}/show-by-hc/${id}`);
    },
    showByIdUsuario(search = '',pagination = {},id) {

        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(pagination['per_page'] ? {per_page : pagination['per_page']} : {}),
            ...(pagination['page'] ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };

        return Service.get(`${baseurl}/show-by-usuario/${id}?`, {
            params : {
                ...params
            }
        });
    },
    showProcedimientosRealizadosByHc(id) {
        return Service.get(`${baseurl}/show-procedimientos-realizados-by-hc/${id}`);
    },
}